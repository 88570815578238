.l-map-ui {
	pointer-events: none;
	position: relative;
	> * {
		pointer-events: auto;
	}
	&__top-start {
		position: absolute;
		z-index: 50;
		top: $spacer;
		left: $spacer;
	}
	&__top-end {
		position: absolute;
		z-index: 50;
		top: $spacer;
		right: $spacer;
	}
	&__top-center {
		position: absolute;
		left: 50%;
		width: auto;
		top: $spacer;
		transform: translateX(-50%);
		z-index: 50;
	}
	&__bottom-end {
		position: absolute;
		z-index: 50;
		bottom: #{$spacer * 2.5};
		right: $spacer;
	}
	.c-scaleline {
		position: absolute;
		bottom: #{$spacer * 2.5};
		right: #{($spacer * 2) + 3rem};
		.ol-scale-line {
			background-color: transparent;
			bottom: 0;
			right: 0;
			&-inner {
				color: $dark;
				font-weight: 600;
				border: 2px solid $dark;
				border-top: 0;
				height: 1.5em;
				line-height: .75em;
			}
		}
	}
	// .c-signatur {
	// 	.c-accordion-item {
	// 		border: 0 !important;
	// 	}
	// 	.c-accordion-button {
	// 		padding: 0 !important;
	// 		> div {
	// 			&:first-child {
	// 				padding-left: $spacer;
	// 			}
	// 			&:last-child {
	// 				padding-right: #{$spacer};
	// 			}
	// 		}
	// 	}
	// }
	.c-attribution {
		position: absolute;
		bottom: 0;
		right: 0;
		background-color: rgba($white, .75);
		border-radius: $border-radius 0 0 0;
		// width: 20em;
		// height: 1.5em;
		font-size: .8em;
		font-weight: 500;
		padding: calc($spacer / 7) $spacer;
		color: $dark;
	}
	.c-zoom-actions {
		border-radius: $border-radius;
		.btn {
			&:first-child {
				border-bottom-left-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}
			&:last-child {
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}
			.ember-view {
				> svg {
					height: 2em !important;
					width: auto !important;
				}
			}
		}
	}
	.c-layer-toggler {
		position: absolute;
		left: $spacer;
		bottom: $spacer;
		z-index: 50;
		transition: 0.3s ease;
		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
		// .btn-group {
		// 	> input {
		// 		&:focus {
		// 			+ label {
		// 				border: 2px solid red !important;
		// 			}
		// 		}
		// 	}
		// 	> label {
		// 		display: none;
		// 		width: 4.5rem;
		// 		height: 4.5rem;
		// 		border: .25em solid white;
		// 		background-size: cover;
		// 		border-radius: #{$border-radius * 2};
		// 		box-shadow: $box-shadow;
		// 		&.daempet {
		// 			background-image: url(/img/daempet.png);
		// 		}
		// 		&.orto {
		// 			background-image: url(/img/ortofoto.png);
		// 		}
		// 		&:not([checked]) {
		// 			display: block;
		// 		}

		// 		span {
		// 			color: red;
		// 			position: absolute;
		// 			bottom: .5em;
		// 			left: .5em;
		// 			font-size: .85em;
		// 			line-height: 1;
		// 		}
		// 	}
		// }
		> button {
			padding: 0;
			border: 0;
			background-color: transparent;
			&:focus {
				> div {
					box-shadow: 0 0 0 0.25rem rgb($primary, 50%) !important;
				}
			}
			&:not(.active) {
				// display: block;
			}
			> div	{
				position: relative;
				// width: 4.5rem;
				// height: 4.5rem;
				width: 5rem;
				height: 5rem;
				background-size: cover;
				border: .25em solid white;
				border-radius: #{$border-radius * 2};
				box-shadow: $box-shadow;
				&:after {
					content: "";
					position: absolute;
					right: 0;
					left: 0;
					bottom: 0;
					height: 40px;
					background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.5)));
					background-image: linear-gradient(transparent,rgba(0,0,0,.5));
					z-index: 1;
					border-radius: $border-radius;
				}
				&.daempet {
					background-image: url(/img/daempet.png);
				}
				&.orto {
					background-image: url(/img/ortofoto.png);
				}
				> div {
					color: white;
					position: absolute;
					bottom: .7em;
					left: .5em;
					font-size: .8em;
					line-height: 1;
					z-index: 10;
				}
			}
		}
	}
	.c-map-list-loading {
		font-weight: 600;
	}
	.c-toggle-auto-zoom {
		position: relative;
		.form-check-input {
			position: absolute;
			left: 1em;
			top: 50%;
			margin: 0;
			transform: translateY(-50%);
			&:focus + .form-check-label {
				box-shadow: 0 0 0 0.25rem rgb(211, 212, 213, 50%);
				// border: 4px solid red;
			}
		}
		.form-check-label {
			@extend .btn;
			box-shadow: $box-shadow;
			background-color: white;
			font-weight: 600;
			padding-left: 2.25rem;
		}
	}
}

.ol-scale-line {
	bottom: $spacer;
	right: #{$spacer * 5};
	left: auto;
}

.ol-zoom {
	top: auto;
  left: auto;
  bottom: $spacer;
  right: $spacer;
}

.ol-box { //.ol-dragzoom – trækzoom boks
  border-radius: .25em;
  border: 2px dashed $primary;
}
