$component-accordion: '.c-accordion';
.c-accordion {
    &-item {
        &:not(:last-child) {
            border-bottom: 0 !important;
        }
        &.is-expanded {
            #{$component-accordion}-collapse {
                display: block !important;
                // max-height: 0;
            }
            #{$component-accordion}-button {
                background-color: transparent !important;
                #{$component-accordion}-icon {
                    color: $dark !important;
                }
            }
        }
        // &:not(.is-expanded) {
        //     #{$component-accordion}-icon {
        //         color: tint-color($dark, 50%);
        //     }
        // }
        &.has-hover, &:hover {
            background-color: tint-color($primary, 90%);
            opacity: 1;
            // background-color: tint-color($primary, 97%);
            // opacity: 1;
        }
    }
    &-header {
        margin-bottom: 0;
        #{$component-accordion}-button {
            flex-wrap: nowrap;
            transition: 0.3s;
            border-radius: 0;
            > .col:not(.col-auto) {
                padding-right: 0 !important;
                width: calc(100% - 10em);
            }
            .c-advanced-list-item { //hvis advanced list bruges som header
                padding: 0;
                > .c-avatar {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            #{$component-accordion}-icon {
                color: tint-color($dark, 50%);
            }
            &:hover {
                // color: $primary;
				// border-color: $primary;
				// background-color: tint-color($primary, 97%);
                opacity: 1;
                #{$component-accordion}-icon {
                    color: $dark;
                }
            }
        }
    }
    &-collapse {
        // max-height: 0;
        display: none;
        //transition: all 0.2s ease;
        //overflow: hidden;
    }
    &--cards {
        border-radius: $border-radius;
        background-color: white;
        > .ember-view { // Skal fjernes (er placeret længere nede) når ember opdateres
            &:first-child > #{$component-accordion}-item {
                border-radius: $border-radius $border-radius 0 0;
                border-top: 0 !important;
            }
            &:last-child > #{$component-accordion}-item {
                border-radius: 0 0 $border-radius $border-radius;
            }
            > #{$component-accordion}-item {
                border-top: 0 !important;
            }
        }
        > #{$component-accordion}-item, > div.ember-view > #{$component-accordion}-item { // efter "," skal fjernes når ember opdateres
            // border-top: 0 !important; 
            // &:first-child { //skal indkommenteres igen når ember er opdateret
            //     border-radius: $border-radius $border-radius 0 0;
            //     border-top: 0 !important;
            // }
            // &:last-child {
            //     border-radius: 0 0 $border-radius $border-radius;
            // }
            > #{$component-accordion}-header {
                > #{$component-accordion}-button {
                    > div {
                        padding: 0 #{$spacer * 1.5};
                    }

                }
                .c-avatar:first-of-type {
                    margin-right: #{$spacer * 1.5};
                }
            }
            > #{$component-accordion}-collapse {
                padding-right: #{$spacer * 1.5} !important;
                padding-left: #{$spacer * 1.5} !important;
            }
        }
    }

}


// Gammel accordion modificeret
// .c-accordion {
//     @extend .card;
//     &__item {
//         position: relative;
//         // background-color: rgba(red, .25);
//         > .slide {
//             padding: 16px 10px;
//         }
//         > .c-accordion__header {
//             padding: 16px 10px;
//             color: $primary-color-400;
//             border-top: $main-border;
//             font-weight: 600;
//             transition: color 0.3s;
//             background-color: rgba(green, .15);
//         }
//         &--large {
//             .c-accordion__header {
//                 background-color: rgba(blue, .15);
//             }
//         }
//         &.is-open {
//             > .c-accordion__collapse {
//                 max-height: 3000px;
//                 transition: all 1s ease;
//             }
//         }
//     }
//     &__collapse { // Nested inside &__item
//         max-height: 0;
//     	transition: all 0.2s ease;
//     	overflow: hidden;
//     	> * {
//     		padding: 12px 10px 30px 10px;
// 		}
// 		ul.bbr {
// 			display: block;
// 		}
// 		figure.eksempel {
// 			width: 100%;
// 			height: 290px;
// 			background-image: url(/img/graf-eksempel.svg);
// 			background-repeat: no-repeat;
// 			background-size: 90%;
// 			background-position: center 25px;
// 		}
//     }
// }