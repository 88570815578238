
//checkbox
.form-check {
    &:not(.form-switch) { //checkbox (not switch)
        .form-check-input {
            border-radius: calc($border-radius / 1.5);
            font-size: 16px; //bør fjernes med body font-size
            border: 1px solid rgb($dark, 30%);
            &:hover {
                border-color: tint-color($primary, 25%);
            }
            &:checked {
                background-color: $primary;
                border-color: $primary;
                background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMCAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjAgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDozLjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTQuMiwxMGwzLjksMy45bDcuNy03LjciLz4KPC9zdmc+Cg==")
            }
            &:focus {
                border-color: $primary;
                box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
            }
        }
    }
    @each $color, $value in $theme-colors { //color map
        &--#{$color} {
            .form-check-input {
                &:hover {
                    border-color: $value !important;
                }
                &:checked {
                    background-color: $value !important;
                    border-color: $value !important;
                }
                &:focus {
                    border-color: $value !important;
                    // box-shadow: 0 0 0 0.25rem rgba($value, 0.25);
                }
            }
            // &:hover {
            //     .form-check-label {
            //         background-color: tint-color($value, 95%) !important;
            //     }
            // }
        }
    }

    &--btncheckbox {
		margin: 6px 0;
		position: relative;
		padding: 0;
		.form-check-label {
			padding: #{$spacer * 0.5} $spacer #{$spacer * 0.5} #{$spacer * 2.5};
			display: block;
			text-align: left;
			background-color: transparent;
        	border-color: transparent !important;
			color: tint-color($secondary, 25%);
            line-height: inherit;
            display: flex;
            align-items: center;
			&.btn {
				&:hover {
					background-color: shade-color($light, 4%);
					color: $secondary;
				}
			}
		}
		.form-check-input:checked ~ .form-check-label.btn {
			background-color: $light;
			color: $secondary;
			&:hover {
				background-color: shade-color($light, 4%);
				color: $secondary;
			}
		}

        .form-check-input:checked ~ .c-signatur--new {
            display: block;
        }

		.form-check-input {
			position: absolute;
			top: #{$spacer * 0.8};
			left: #{$spacer * 0.8};
			margin: 0;
		}
	}
}

//switch
.form-switch {
    &--custom {
        padding-left: 3.2em;
        .form-check-label {
            color: tint-color($secondary, 25%);
            font-weight: 500;
        }

        .form-check-input {
            width: 2.5em;
            margin-left: -3.2em;
            background-image: none !important;
            border: none;
            background-color: shade-color($light, 8%);
            position: relative;
            border-radius: 0.5em;
            &:before {
                content: "";
                position: absolute;
                width: 1.4em;
                height: 1.4em;
                border-radius: 50%;
                background-color: #fff;
                box-shadow: 0 1px 4px 0 rgba(#000, 0.3);
                left: -0.2em;
                top: 50%;
                transform: translateY(-50%);
                transition: left 0.15s ease-in-out;
            }
            &:checked {
                background-color: tint-color($primary, 70%);
                &:before {
                    left: 1.3em;
                    background-color: $primary;
                }
            }
        }
        .form-check-input:checked ~ .form-check-label {
            color: $secondary;
        }
    }
}

.form-control {
    &--custom {
        padding: #{$spacer * 0.75} #{$spacer};
        border-color: tint-color($secondary, 50%);
        font-weight: 500;
    }
}
.form-label {
    font-weight: 600;
}
