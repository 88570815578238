.nav {
    &-tabs { //tabs styling
        .nav-item {
            .nav-link {
                color: tint-color($dark, 35%);
                font-weight: 600;
                padding-top: 1.25em;
                padding-bottom: 1.25em;
                border-top: 0 !important;
                border-right: 0 !important;
                border-bottom: 2px solid transparent;
                border-left: 0 !important;
                &.active {
                    color: $dark;
                    border-color: $primary !important;
                    cursor: initial;
                }
                &:hover {
                    color: $dark;
                    // border-color: shade-color($light, 35%);
                    text-decoration: none;
                }
            }
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            display: grid;
            grid-template-columns: repeat(999, auto);
            grid-gap: 0 0; 
            a {
                white-space: nowrap;
            }
        }
    }
}