
/* Tablet Landscape size */
@media only screen and (max-width: 1200px) {
	.l-sidebar-end {
        width: 320px;
    }

    .l-map {
        padding-right: 320px;
    }

    .layer-toggler {
        right: 330px;
    }

    .close-list .l-sidebar-end {
        margin-right: -260px;
    }

    // h3 {
    //     font-size: 14px;
    //     line-height: 20px;
    //     align-items: center;
    //     i { vertical-align: bottom;}
    //     small {line-height: 28px;}
    // }

    // h2 {
    //     font-size: 15px;
    // }

    .table h4 {
        line-height: 16px;
    }

    .c-list-details h2 {
        font-size: 17px;
        line-height: 23px;
    }

    .c-list-details .links a {
        font-size: 14px;
        .icon {
            width: 22px;
            height: 26px;
            background-size: auto 22px;
            &.ledigeboliger {
                background-size: auto 27px;
            }
        }
    }

    .search-cont {
        margin-left: -80px;
    }

    .search-cont .septimasearch {
        width: 400px;
    }

    .filter-sort button {
        padding: 0 10px;
    }

    .filter-cont > div {
        padding: 24px 20px;
    }

}
	

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (max-width: 959px) {
    .modal {
        .modal-container {
            height: auto;
            width: 80%; 
            .tables {
                display: block;
                height: 70vh;
                overflow-y: auto;
                .column {
                    width: 100%;
                    .table {
                        overflow-y: visible;
                    }
                }
            }
            .cards {
                overflow-y: visible;
            }
        }
        .c-list {
            border-top: $main-border;
        }
        .top {
            height: 60px;
            h2 {
                font-size: 17px;
            }
        }
    }

    .column:first-child {
		header {
			box-shadow: none;
		}
    }

    .search-cont {
        margin-left: 0;
    }
    .l-sidebar {
        width: 100%;
    }

    .l-map {
        padding:0 !important;
    }

    .close-list {
        .l-sidebar-end {
            margin-right: 0; 
            margin-left: 0;
            left: 0;
            top: calc( 100% - 50px);
            right: auto;
        }
        .l-sidebar-end {
            border-top: 1px solid $primary-color-200;
            .cont .list-menu {
                opacity: 1;
                .filter-sort {
                    opacity: 0;
                }
            }
        }
        .l-map {
            padding: 0;
        }
        .layer-toggler {
            right: 10px;
        }
    }

    .close-view {
        .l-sidebar-start {
            margin-left: 0;
            top: calc( 100% - 100px);
        }
    }
      
    .close-view .message,
    .close-list .message,
    .message {
        left: 20px;
        right: 20px;
    }

    .c-list header .btn-collapse {
        position: absolute;
        top: 0;
    }

    .l-sidebar header .btn-collapse {
        transform: none;
    }

    .c-list header .btn-collapse > div {
        transform: rotate(90deg);
    }

    .l-sidebar-start header .btn-collapse > div {
        transform: rotate(90deg);
    }

    .layer-toggler {
        bottom: 110px;
        left: auto;
        right: 10px;
    }

    .signatur, .close-view .signatur {
        left: 10px;
        bottom: 110px;
    }
    
    .l-sidebar-start {
        height: calc( 100vh - 130px);
    }

    #details {
        animation: none;
    }

}

/* All Mobile Sizes (devices and browser) */
@media only screen and (min-width: 319px) and (max-width: 767px) { 
    .search-cont {
        display: none;
    }
    .modal {
        .modal-wrapper {
            display: block;
        }
        .modal-container {
            width: 100%; 
            .tables {
                height: calc(100vh - 140px);
            }
        }
    }
}