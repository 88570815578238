$component-advancedlist: '.c-advanced-list';

.c-advanced-list {
	display: block;
    padding: 8px 0;
    position: static;
    transition: box-shadow .28s cubic-bezier(.4,0,.2,1);
    will-change: box-shadow;

	&-item {
		align-items: center;
		display: flex;
		flex: 1 1 100%;
		letter-spacing: normal;
		min-height: 48px;
		outline: none;
		padding: 0 16px;
		position: relative;
		text-decoration: none;
		border-bottom: 1px solid rgba($dark, .15);

		&.bg-light {
			background-color: $light !important;
		}

		> a, > button { //skal fjernes når div item kan laves om til a direkte
			color: $dark;
			align-items: center;
			display: flex;
			flex: 1 1 100%;
			padding: 0 16px;
			margin: 0 -16px;
			min-height: inherit;
			border: 1px solid white;
			width: 100%;
			&:focus {
				outline: 0;
				box-shadow: 0 0 0 0.25rem rgb($dark, 25%);
			}
			&:hover, &.active {
				color: shade-color($primary, 5%);
				border-color: shade-color($primary, 5%);
				background-color: tint-color($primary, 97%);
			}
		}
		.c-avatar {
			margin-right: $spacer;
			margin-bottom: 16px;
			margin-top: 16px;
			display: inline-flex;
			min-width: 24px;
		}
		&:first-child {
			border-top: 1px solid rgba($dark, .15);
		}
		&__title, &__subtitle {
			line-height: 1.4;
			flex: 1 1 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			pointer-events: none;
		}
		&__title {
			font-weight: 600;
			font-size: 1rem;
			align-self: center;
		}
		&__subtitle {
			font-weight: 500;
			font-size: .925rem;
			color: rgba(0,0,0,.7) !important;
		}

		&__action, &__avatar, &__icon { //avatar har andet navn og action ligger udenfor item
			display: inline-flex;
    		min-width: 24px;
		}
		&__icon {
			// min-width: 40px;
			margin-right: #{$spacer * 1.5};
			min-width: auto;
			z-index: 50;
			pointer-events: none;
			> svg, > img {
				pointer-events: all;
			}
			// margin: 16px 0;
			// border-radius: 75em;
			// min-height: 24px;
			// align-items: center;
			// justify-content: center;
			// background-color: tint-color($dark, 90%);
			// > div { //svg container
			// 	line-height: 0;
			// }
			// &:not(:first-child) { //hvis item ikke er første child - altså slut fjernes margin
				svg {
					width: 1.5em;
					height: auto;
				}
			&.is-last { //hvis item ikke er første child - altså slut fjernes margin
				margin-right: 0;
				.c-icon {
					font-size: .85em;
				}
			}
		}
		&--two-line {
			min-height: 64px;
			&#{$component-advancedlist}-item, &#{$component-advancedlist}-item > a  {
				min-height: 64px;
			}
		}
		&--three-line {
			min-height: 88px;
			&#{$component-advancedlist}-item, &#{$component-advancedlist}-item > a {
				min-height: 88px;
			}
		}

		&--loadmore { //loadmore i item i bunden af afdelinger list
			display: block;
			background-color: transparent !important;
			box-shadow: none !important;
			border: none;
			box-shadow: none;
			height: auto;
			color: #748c9f;
			font-weight: 500;
			font-size: 15px;
			text-align: center;
			margin: 0;
			border-color: transparent !important;
			&:before {
				content: "";
				width: 100%;
				position: absolute;
				z-index: 100;
				left: 0;
				top: -100px;
				height: 100px;
				background: linear-gradient(rgba(243, 247, 249, 0), #f3f7f9);
				pointer-events: none;
			}
		}

	}
	&__content {
		align-items: center;
		align-self: center;
		display: flex;
		flex-wrap: wrap;
		flex: 1 1;
		overflow: hidden;
		padding: 12px 0;
		z-index: 50;
		pointer-events: none;
		> * {
			line-height: 1.3;
			flex: 1 0 100%;
		}

		& > :not(:last-child) {
			margin-bottom: .25rem; //#{$spacer/4}
		}

    button {
      pointer-events: initial;
    }
	}
	&__action {
		align-self: center;
		margin: 12px 0;
		display: inline-flex;
		min-width: 24px;
		z-index: 50;
		// background-color: yellow;
	}
	&__goto {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 40;
		margin: 0 !important;
		padding: 0 !important;
		&:hover {
			cursor: pointer !important;
		}
	}
	//modifiers
	&--dense {
		min-height: 40px;
		#{$component-advancedlist}-item {
			min-height: 40px;
			#{$component-advancedlist}__content {
				padding: 8px 0;
			}
		}
		#{$component-advancedlist}__content {
			padding: 8px 0;
		}
		.c-avatar {
			margin-top: 8px;
			margin-bottom: 8px;
			height: 36px;
			min-width: 36px;
			width: 36px;
		}
	}
	//types
	&--cards {
		#{$component-advancedlist}-item {
			border: 0;
			background-color: white;
			margin-bottom: .75em;
			box-shadow: $box-shadow;
			border-radius: $border-radius;
			> a, > button {
				border-radius: inherit;
			}
		}
	}
	&--cases { //specifik for almenGIS er et liste element der er smalt og med en lille avatar
		#{$component-advancedlist}-item {
			// padding: 0 calc($spacer / 2);
			padding-left: calc($spacer / 1.5);
			border: 0;
			background-color: $light;
			margin-bottom: .5em;
			border-radius: $border-radius;
			> a, > button {
				// margin: 0 calc($spacer / -2);
				// padding: 0 calc($spacer / 2);
				margin-left: calc($spacer / -1.5);
				padding-left: calc($spacer / 1.5);
				border-radius: inherit;
			}
		}
	}
	&.is-loading {
		opacity: .65;
		pointer-events: none;
		transition: opacity linear 100ms;
		overflow: hidden;
		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
		// > {{$component-advancedlist}}-item {

		// }
	}

}
