/* MODAL COMMON STYLES
-------------------------------------------------------------- */
.modal {
	display: block;
	// opacity: 1;
	// -webkit-transition: visibility 0s 0.2s, opacity 0.2s ease;
	// -moz-transition: visibility 0s 0.2s, opacity 0.2s ease;
	// transition: visibility 0s 0.2s, opacity 0.2s ease;

	// .modal-wrapper {
	// 	display: table-cell;
	// 	vertical-align: middle;
	// }

	// .modal-container {
	// 	background-color: #fff;
	// 	min-height: 400px;
	// 	max-height: 80vh;
	// 	width: 900px;
	// 	margin: 0 auto;
	// 	box-shadow: 0 0 15px 0 rgba(24,49,71,0.2);
	// }

	// .top {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	height: 80px;
	// 	padding: 0 0 0 25px;
	// 	.close {
	// 		height: 100%;
	// 		padding: 0 25px;
	// 	}
	// 	h2 {
	// 		font-size: 20px;
	// 		font-weight: 600;
	// 		i {
	// 			width: 30px;
	// 			height: auto;
	// 			color: $primary-color-500;
	// 			svg {
	// 				width: 24px;
	// 				height: 19px;
	// 				margin-top: -5px;
	// 			}
	// 		}
	// 	}
	// }
	// header {
	// 	background-color: #fff;
	// 	box-shadow: 0 0 15px 0 rgba(24,49,71,0.15);
	// }
}


/* MODAL - DEFAULT MODAL-CONTAINER
-------------------------------------------------------------- */
// .modal .modal-container {
// 	height: 600px;

// 	.tables {
// 		display: flex;
// 		border-top: $main-border;
// 		height: calc( 100% - 80px);
// 		.column {
// 			overflow: hidden;
// 			width: 50%;
// 			position: relative;
// 			&:first-child {
// 				border-right: $main-border;
// 			}

// 			header {
// 				position: absolute;
// 				left: 0;
// 				top:0;
// 				width: 100%;
// 				height: 50px;
// 				display: flex;
// 				align-items: center;
// 				padding: 0 25px;
// 				z-index: 10;
// 				h3 {
// 					display: flex;
// 					align-items: center;
// 					color: $primary-color-500;
// 					font-weight: 600;
// 					margin: 0;
// 					i {
// 						font-style: normal;
// 						position: relative;
// 						display: block;
// 						background-color: darken($primary-color-100, 3%);
// 						width: 28px;
// 						height: 28px;
// 						border-radius: 50%;
// 						padding-top: 4px;
// 						margin-left: 8px;
// 						text-align: center;
// 					}
// 				}
// 			}
// 			.table {
// 				margin-top: 50px;
// 				height: calc( 100% - 50px );
// 				overflow-y: auto;
// 				padding: 25px;
// 			}
// 		}
// 	}

// 	.cards {
// 		padding: 9px 25px;
// 		overflow-y: auto;
// 		margin-top: 50px;
// 		height: calc( 100% - 50px);

// 		.card {
// 			height: auto;

// 			h2 {
// 				margin-bottom: 5px;
// 			}
// 		}
// 	}

// 	button {
// 		span, i {color: $primary-color-400; display: inline-block;vertical-align: middle;}
// 		&:focus { outline: none;}
// 		&:hover {
// 			cursor: default;
// 			span {text-decoration: none;}
// 			span, i {color: $primary-color-400;}
// 		}
// 	}
// }

// /* MODAL - BRUGERINDSTILLINGER
// -------------------------------------------------------------- */
// .modal .settings-cont,
// .modal .gem-cont {
// 	.c-list {
// 		background-color: #fff;
// 	}

// 	.card {
// 		box-shadow: none;
// 		background-color: #EDF3F7;
// 		transition: background-color 0.3s;
// 		&:hover {
// 			background-color: darken($primary-color-100, 5%);
// 			border: 1px solid transparent;
// 		}
// 		ul {
// 			justify-content: space-between;
// 			position: relative;
// 			li.share {
// 				position: absolute;
// 				right: 0;
// 				top: -28px;
// 			}
// 			li.delete {
// 				margin-left: auto;
// 			}
// 		}
// 		i {
// 			padding-right: 8px;
// 			color: $primary-color-700;
// 			svg {
// 				width: 21px;
// 				height: 21px;
// 				margin-top: -2px;
// 			}
// 		}
// 		button {
// 			cursor: pointer;
// 			opacity: 0.6;
// 			i {
// 				color: $primary-color-500;
// 				padding-right: 0;
// 			}
// 			&:hover { opacity: 1;
// 				i {
// 					color: $primary-color-700;
// 				}
// 			}
// 		}

// 		.time {
// 			color: $primary-color-500;
// 		}

// 		.table {
// 			padding: none;
// 		}
// 	}

// 	.top h2 {
// 		i {
// 			color: $primary-color-400;
// 			width: 35px;
// 		}
// 		svg {
// 			width: 24px;
// 			height: 24px;
// 		}
// 	}

// 	.table {
// 		button {
// 			font-size: 15px;
// 			font-weight: 500;
// 			padding: 0.8em 1em;
// 			margin: 5px 0;
// 			background-color: $primary-color-100;
// 			&.selected {
// 				i, span {
// 					color: $primary-color-700;
// 				}
// 			}
// 			i, span {padding-right: 5px;}
// 			&:hover {
// 				opacity: 1;
// 				background-color: darken($primary-color-100, 5%);
// 			}
// 		}
// 		li:nth-child(odd) {
// 		background-color: transparent;
// 		}
// 	}
// }

// .form {
// 	display: block;
// 	input {
// 		display: block;
// 		width: 100%;
// 		border: 1px solid $primary-color-400;
// 		border-radius: 4px;
// 		background-image:none;
// 		background-color:transparent;
// 		box-shadow: none;
// 		font-size: 16px;
// 		font-weight: 500;
// 		padding: 15px 13px;
// 		color: $primary-color-700;
// 		&:focus {
// 			outline: none;
// 			border: 1px solid $primary-color-400;
// 		}
// 		&::placeholder {
// 			color: $primary-color-300;
// 		}

// 		&:-ms-input-placeholder {
// 			color: $primary-color-300;
// 		}

// 		&::-ms-input-placeholder {
// 			color: $primary-color-300;
// 		}
// 	}
// 	label.navn {
// 		display: block;
// 		color: $primary-color-700;
// 		font-weight: 600;
// 		margin-bottom: 12px;
// 	}
// }

// .form-buttons {
// 	height: 90px;
// 	button {
// 		height: 50px;
// 		font-size: 15px;
// 	}
// 	.light {
// 		background: $primary-color-100;
// 	}
// }

// .modal .gem-cont {
// 	.column:first-child {
// 		padding: 76px 35px 40px 35px;
// 	}
// }

// .table {
//   .checkbox {
//     div { display: flex; align-items: center;}
//     svg {
//       width: 18px;
//       height: 18px;
//     }

//     .marked {
//       display: none;
//     }
//   }

//   .selected {
//     color: $primary-color-700;

//     .checkbox {
//       .marked {
//         display: inline-block;
//       }

//       .blank {
//         display: none;
//       }
//     }

//     .level-3 {
//       li {
//         color: $primary-color-500;
//       }
//     }
//   }
// }
