.l-modal {
    display: block;
    overflow-y: hidden !important;
    &__content {
        height: 600px;
        max-height: 80vh;
        overflow: auto;
        @include media-breakpoint-up(md) {
            overflow: hidden;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: calc(100% - 5rem);
        > .row {
            height: 100%;
            @include media-breakpoint-down(md) {
                height: auto;
            }
        }
    }

    &__header {
        height: 5rem;
        padding: 0 1.5rem;
        .modal-title {
            display: flex;
            align-items: center;
            .icon {
                margin-right: 1rem;
                color: tint-color($secondary, 25%);
            }
        }
    }

    &__subheader {
        height: 3.5rem;
        width: 100%;
        top: 0;
        z-index: 1;
        flex: 0 0 auto;
        padding: 0 1.5rem;
        border-bottom: $main-border;
        align-items: center;
        @include media-breakpoint-down(md) {
            border-top: $main-border;
        }
        .h5 {
            font-weight: 600;
            color: tint-color($secondary, 25%);
        }
        .text-muted {
            color: tint-color($secondary, 50%) !important;
        }
        &:first-child {
            @include media-breakpoint-down(md) {
                border-top: 0;
            }
        }
    }

    &__list {
        height: inherit;
        display: flex;
        flex-direction: column;
    }
    &__list-scroll {
        flex: 1 1 100%;
        @include media-breakpoint-up(md) {
            overflow-y: auto;
        }
        > .c-list {
            height: auto;
        }
    }
}

.dropshadow {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($secondary, 0.7);
}