$component-skeleton-loader: '.c-skeleton-loader';
.c-skeleton-loader {
    &__heading, &__text {
        background: shade-color($light, 2%);
    }
    &__heading {
        border-radius: 12px;
        // height: 24px;
        height: 16px;
        margin: 1px 0;
        width: 45%;
    }
    &__paragraph {
        #{$component-skeleton-loader}__text {
            border-radius: 6px;
            flex: 1 0 auto;
            height: 12px;
            margin-bottom: 6px;
            &:first-child {
                max-width: 100%;
            }
            &:nth-child(2) {
                max-width: 50%;
            }
            &:nth-child(3) {
                max-width: 70%;
            }
        }
    }
}