/**
 * This contains application SaSS (.scss) import order.
 */

@import 'ember-power-select/themes/bootstrap';
@import 'ember-power-select';

// Bootstrap
@import "node_modules/bootstrap/scss/functions"; //Required
@import "node_modules/bootstrap/scss/variables"; //Required
@import "node_modules/bootstrap/scss/mixins"; //Required
@import './bootstrap/_settings.scss'; //Custom tilpasninger af Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap.scss'; //Bootstrap full - This is Options so could be seperate used packages see documentation

// General
@import './old/_app.scss'; //Før strukturering af SASS
@import '_global.scss';
@import '_poi.scss';
@import '_accessibility.scss';;
@import '_typography.scss';
@import '_mixins.scss';
@import '_header.scss';
@import '_footer.scss';

// Bootstrap
@import './bootstrap/_utilities.scss';
@import './bootstrap/_badge.scss';
@import './bootstrap/_forms.scss';
@import './bootstrap/_nav.scss';
@import './bootstrap/_btn.scss';
@import './bootstrap/_btn-group.scss';
@import './bootstrap/_list-group.scss';
@import './bootstrap/_dropdown.scss';

// Components
@import './components/_c-sidebar.scss';
@import './components/_c-display.scss';
@import './components/_c-toolbar.scss';
@import './components/_c-accordion.scss'; // (Component)
@import './components/_c-metadata.scss';
@import './components/_c-case-bits.scss';
@import './components/_c-avatar.scss';
@import './components/_c-list.scss'; //list
@import './components/_c-skeleton-loader';
@import './components/_c-advanced-list.scss'; //advanced list (Component)
@import './components/_c-subheader.scss';
@import './components/_c-filter-tags.scss';
@import './components/_c-signatur.scss';

// Pages

// Layouts
@import './layouts/_l-content.scss';
@import './layouts/_l-sidebar.scss';
@import './layouts/_l-map.scss';
@import './layouts/_l-map-ui.scss';
@import './layouts/_l-modal.scss';



// Temp
.demo-image-1 {
  background-image: url(https://images.pexels.com/photos/373965/pexels-photo-373965.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260);
}
.demo-image-2 {
  background-image: url(https://images.pexels.com/photos/1115804/pexels-photo-1115804.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
}
.demo-image-3 {
  background-image: url(https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
}

