.badge {
    padding: .65em 1em;
    font-weight: 600;
    // display: flex;
    align-items: center;
    &.bg { //loop through all theme-colors and tint them on badge ----- brug bg-tint-primary istedet
        @each $color, $value in $theme-colors {
            &-#{$color} {
                background-color: tint-color($value, 80%) !important;
                border: 1px solid tint-color($value, 80%);
                color: $value !important;
            }
        }
    }
    > .btn {
        padding: 0;
        line-height: 0;
        border: 0;
        padding: 0;
        margin: -.15em;
        min-height: initial;
        .c-icon {
            margin-right: 0;
            svg {
                padding: .35em;
                margin-top: 0;
            }
        }
        @each $color, $value in $theme-colors {
            &-#{$color} {
                // background-color: tint-color($value, 80%) !important;
                background-color: transparent;
                color: $value !important;
                &:hover {
                    background-color: tint-color($value, 80%) !important;
                }
            }
        }
    }
    > svg, >.c-icon svg  {
        width: auto;
        height: 1.25em;
        margin-top: -.15em;
        margin-bottom: -.15em;
    }
    &--icon {
        line-height: 0;
    }
}