.dropdown {
    &-menu {
        border: 0;
        box-shadow: $box-shadow; 
    }
    &-item {
        border-radius: 0;
        font-weight: 500;
    }
    &-toggle:after {
        margin-left: -3px;
    }

    .alert {
        top: 3.5em;
    }

    .btn--icon {
        min-width: auto;
    }
}