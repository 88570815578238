/* LOADING ANIMATION
-------------------------------------------------------------- */
.loading-cards {
	.loading-card {
		height: 84px;
		.anim-cont {
			display: flex;
		    height: 100%;
		    flex-direction: column;
		    animation-duration: 3s;
		    animation-fill-mode: forwards;
		    animation-iteration-count: infinite;
		    animation-name: loadingAnimation;
		    animation-timing-function: linear;
		    overflow: hidden;
		    background: linear-gradient(to right, #f3f8fb 8%, #dfe9ef 18%, #f3f8fb 33%) 0% 0%/1024px 110px;

		   .mask {
			   	display: flex;
			    width: 100%;
			    flex-direction: column;
			    height: 100%;

			    > div {
			    	background: #fff;
			    }

			    .mask-item-1 {
			    	width: 30%;
					height: 22px;
					margin-left: 70%;
			    }

			    .mask-item-2 {
			    	height: 14px;
			    }

			    .mask-item-3 { 
			    	width: 10%;
    				height: 16px;
    				margin-left: 90%;
			    }
			}
		}
	}
}

@keyframes loadingAnimation {
	0% {
	    background-position: -1024px 0px;
	}

	100% {
	    background-position: 1024px 0px;
	}
}