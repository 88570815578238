.filter-sort {
	margin-left: auto;
	button {
		color: $primary-color-400;
		padding: 0 12px;
		height: calc(100% - 1px);
		position: relative;
		&:hover, &.open {
			color: $primary-color-700;
			opacity: 1;
		}

		> div {
			display: inline-block;
		}

		svg {
			width: 22px;
			height: 17px;
			margin-top: -3px;
		}
	}

	button.open { //button.open
		color: $primary-color-700;
		&:before {
			content: '';
			position: absolute;
			bottom: -1px;
			right: 18px;
			border: 8px solid transparent;
			border-bottom-color: lighten($primary-color-300, 15%);
		}
		&:after {
			content: '';
			position: absolute;
			bottom: -2px;
			right: 19px;
			border: 7px solid transparent;
			border-bottom-color: #fff;
		}
	}
}

.filter-cont {
	background: #ffffff;
	width: 100%;
	height: auto;
	border-radius: 0;
	overflow: hidden;
	font-weight: 500;
	position: relative;
	z-index: 1;
	> div {
		padding: 26px 40px;
		// border-top: $main-border;
	}
	h3 {
		font-weight: 700;
		font-size: 15px;
		padding-bottom: 12px;
	}

	label {
		display: block;
		color: $primary-color-500;
		margin: 8px 0;
		font-weight: 600;
	}
	.dropdown {
		&.open {
			max-height: 400px;
			.icon {
				transform: rotate(180deg);
			}
		}
		border: 1px solid $primary-color-200;
		border-radius: 3px;
		width: 100%;
		background: lighten($primary-color-100, 1%);
		max-height: 36px;
		overflow: hidden;
		margin-bottom: 25px;
		transition: 0.3s;
		> div, .heading {
			padding: 0 14px;
		}
		.heading {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			outline: none;
			.selected {
				text-align: left;
				font-weight: 700;
				white-space: nowrap;
				max-width: 85%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.icon {
				transition: 0.3s;
			}
		}
		.heading, input {
			height: 36px;
		}
		input {
			width: 100%;
			padding: 0 8px;
			margin: 4px 0 16px 0;
			font: inherit;
			border: 1px solid $primary-color-200;
			border-radius: 3px;
			&::placeholder {color: $primary-color-400;opacity: 1;}
			&:-ms-input-placeholder {color: $primary-color-400;}
			&::-ms-input-placeholder {color: $primary-color-400;}
		}
		ul {
			border-top: 1px solid $primary-color-200;
			max-height: 180px;
			overflow-y: auto;
			li {
				padding: 12px 22px;
				border-bottom: 1px solid lighten($primary-color-200, 10%);
				background: #ffffff;
				color: $primary-color-500;
				transition: 0.2s;
				&.selected {
					color: $primary-color-700;
					font-weight: 700;
					background-color: #ecf9fb;
				}
				&:hover {
					background: transparent;
					color: $primary-color-700;
					cursor: pointer;
				}
				&:last-child {
					border-bottom: none;
				}
				&:nth-child(-n+5) {
					border-bottom: 1px solid lighten($primary-color-200, 10%);
				}
			}

		}
	}
	.buttons {
		height: auto;
		padding: 10px 0 0 0;
		// justify-content: center;
		button {
			// border: 2px solid darken( $primary-color-100, 3% );
			background: $primary-color-100;
			flex-basis: 30%;
			color: $primary-color-500;
			&:hover {
				background: #e3ebef;
				color: $primary-color-700;
			}
			&:last-child {
				background: darken($primary-color-100, 10%);
				color: $primary-color-700;
			}
		}
	}

	max-height: 0;
	transition: max-height 0.3s ease;

	&.open {
		opacity: 1;
		max-height: 1000px;
	}
}

.close-list .c-list .cont {

	.list-menu, .filter-cont, .filter-tags {
		opacity: 0;
		max-height: 0;
		transition: none;
	}
}

.c-list .wrap {
	max-height: 0;
	visibility: hidden;
	transition: 0s;
	&.open {
		transition: all 0s 0.2s;
		max-height: 500px;
		visibility: visible;
	}
	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

.filter-tags {
	font-weight: 600;
	color: $primary-color-500;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 12px 10px 10px 10px;
	border-bottom: 2px solid darken($primary-color-100, 3%);
	background-color: lighten($primary-color-100, 2%);
	.tag {
		padding: 2px 2px 2px 8px;
		background: lighten($primary-color-200, 15%);
		border-radius: 5px;
		margin: 6px;
	}
	button {
		padding: 2px 4px;
		opacity: 0.6;
		&:hover {
			opacity: 1;
		}
	}
	svg {
		width: 13px;
		height: 13px;
		margin-bottom: 2px;
	}
}

