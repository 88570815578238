.c-toolbar {
	height: 100%;
	z-index: 10;
	&__menu {
		width: $c-toolbar__menu-width;
	}
	&__panel {
		width: $c-toolbar__panel-width;
		margin-left: -$c-toolbar__panel-width;
		opacity: 0;
		transition-property: margin-left, opacity;
    	transition-duration: 0.2s;
    	transition-timing-function: cubic-bezier(0.0,0.0,0.2,1);
    	transition-delay: 0s, 0.15s;
		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
	}
	// type to mobile
	@include media-breakpoint-down(md) {
		width: 100%;
		z-index: 99;
		// position: absolute;
		// top: 0;
		// left: 0;
		// background-color: transparent !important;
		// box-shadow: none !important;
		// // height: initial;
		// .c-toolbar__panel {
		// 	box-shadow: $box-shadow;
		// }
		// .c-toolbar__menu {
		// 	// padding: 0 !important;
		// 	border: 0 !important;
		// }
		// .btn-group-vertical { //placeres her fordi det kun er mobil under c-toolbar dette skal ske
		// 	.btn {
		// 		background-color: white;
		// 		opacity: 1;
		// 		border-radius: 10em !important;
		// 		box-shadow: $box-shadow;
		// 		margin-bottom: 0.85rem;
		// 		&.active {
		// 			background-color: $secondary;
		// 			color: white;
		// 		}
		// 	}
		// }
		// &.is-visible {
		// 	left: 0;
		// 	right: auto;
		// }
	}
	// visible/hidden slider
	// @include media-breakpoint-down(md) {
	// 	position: absolute;
	// 	left: 0;
	// }
	// transition: left linear 300ms;
	// &.is-hidden {
	// 	left: -100%;
	// 	right: auto;
	// }
	// &.is-visible {
	// 	left: 0;
	// 	right: auto;
	// }
}

.c-toolbar-is-open .c-toolbar__panel {
	margin-left: 0;
	opacity: 1;
}


//skal flyttes::

.l-content {
	&.c-toolbar-is-open {
		.c-toolbar {
			@include media-breakpoint-down(md) {
				width: 100%;
				// background-color: rgba($dark, .35) !important;
			}
		}
	}
}

.c-scaleline {
	@include media-breakpoint-down(md) {
		right: $spacer !important;
	}
}
.c-zoom-actions {
	@include media-breakpoint-down(md) {
		display: none;
	}
}