/**
 * Bootstrap settings
 * (find mulige settings i boostrap .variables under node_modules>bootstrap>scss>_variables.scss)
 */


// Standard Color Maps
// $primary: #28757b; //AlmenGIS Grøn
$primary: #2E8188; //AlmenGIS Grøn
// $primary: #32a674; //LBF Grøn
$secondary: #2D4450; //LBF Blå
$success: #2fb97a;
$light: #f1f6f8;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warning": $warning,
  "danger": $danger,
  "success": $success,
  "dark": $dark,
  "light": $light,
);

// Custom colors
// $pink: darkmagenta;
$colorcustom: $success;

$custom-colors: (
  "pink" : $pink,
  "colorcustom" : $colorcustom,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// Custom Sags farver
$case-0:rgb(73, 174, 182,1); //neutral
$case-1: #00b3ff; //renovering
$case-2: #AC00FF; //infrastruktur
$case-3: #0035b5; //kapital
$case-4: #FF00C7; //husleje
$case-5: #FF9B1F; //boligsocial
$case-6: #6be600; //regnskab

$case-colors: (
  "case-0" : $case-0,
  "case-1" : $case-1,
  "case-2" : $case-2,
  "case-3" : $case-3,
  "case-4" : $case-4,
  "case-5" : $case-5,
  "case-6" : $case-6,
);
$theme-colors: map-merge($theme-colors, $case-colors);

// Custom Boligtyper colors (NOT PART OF COLOR LOOP)
$boligtype-famileboliger:#423CD6;
$boligtype-aeldreboliger: #36BA45;
$boligtype-ungdomsboliger: #E00AB7;

$boligtype-colors: (
  "familieboliger" : $boligtype-famileboliger,
  "aeldreboliger" : $boligtype-aeldreboliger,
  "ungdomsboliger" : $boligtype-ungdomsboliger,

);

// Custom Lokalplaner colors (NOT PART OF COLOR LOOP)
$lokalplan-bolig:#A84000;
$lokalplan-blandet-bolig-erhverv: #D73838;
$lokalplan-erhverv: #34C3C3;
$lokalplan-center-butikker: #D748D7;
$lokalplan-rekreativt: #19D719;
$lokalplan-sommerhus: #B9A66A;
$lokalplan-offentlige: #C7C700;
$lokalplan-teknisk-trafik: #2D6264;
$lokalplan-land: #055005;
$lokalplan-andet: #787878;
$lokalplan-ikke-reguleret: #383838;
$lokalplan-kompleks: #000000;

$lokalplan-colors: (
  "bolig" : $lokalplan-bolig,
  "blandet-bolig-erhverv" : $lokalplan-blandet-bolig-erhverv,
  "erhverv" : $lokalplan-erhverv,
  "center-butikker" : $lokalplan-center-butikker,
  "rekreativt" : $lokalplan-rekreativt,
  "sommerhus" : $lokalplan-sommerhus,
  "offentlige" : $lokalplan-offentlige,
  "teknisk-trafik" : $lokalplan-teknisk-trafik,
  "land" : $lokalplan-land,
  "andet" : $lokalplan-andet,
  "ikke-reguleret" : $lokalplan-ikke-reguleret,
  "kompleks" : $lokalplan-kompleks,
);

// Custom POI colors (NOT PART OF COLOR LOOP)
$poi-daginstitution:#0C395D;
$poi-grundskole: $poi-daginstitution;
$poi-uddannelse: $poi-daginstitution;
$poi-togstation: #1A73E8;
$poi-stogstation: $poi-togstation;
$poi-metro: $poi-togstation;
$poi-letbane: $poi-togstation;
$poi-busstop: $poi-togstation;
$poi-kulturhus: #743D98;
$poi-bibliotek: $poi-kulturhus;
$poi-borgerservice: #428414;
$poi-hospital: #B42419;
$poi-laegepraksis: $poi-hospital;
$poi-jobcenter: #06427C;
$poi-idraet: #26762C;
$poi-dagligvare: #EA681E;

$poi-colors: (
  "daginstitution" : $poi-daginstitution,
  "grundskole" : $poi-grundskole,
  "uddannelse" : $poi-uddannelse,
  "togstation" : $poi-togstation,
  "s-togstation" : $poi-stogstation,
  "metro" : $poi-metro,
  "letbane" : $poi-letbane,
  "busstop" : $poi-busstop,
  "kulturhus" : $poi-kulturhus,
  "bibliotek" : $poi-bibliotek,
  "borgerservice" : $poi-borgerservice,
  "hospital" : $poi-hospital,
  "laegepraksis" : $poi-laegepraksis,
  "jobcenter" : $poi-jobcenter,
  "idraet" : $poi-idraet,
  "dagligvare" : $poi-dagligvare,
);


/* ***** Font Sizes ***** */
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

// Negative margins enable
$enable-negative-margins: true;