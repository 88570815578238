.c-display {
	position: relative;
	height: 22rem;
	// background: yellow;
	> .btn {
		position: absolute;
		bottom: #{$spacer * 1.5};
		left: #{$spacer * 1.5};
		width: auto;
		z-index: 10;
	}
	&__image {
		// background-color: rgba(red, .15);
		> button {
			height: 100%;
			width: 100%;
			border: 0;
			background-size: cover;
			background-position: center;
			&:disabled {
				opacity: 1; //denne er kun indtil vi har fået lavet modal med carousel
			}
		}
		&--primary {
			// background-color: rgba(orange, .15);
		}
	}
	&__show-all { //btn
		&:focus {
			box-shadow: 0 0 0 0.25rem rgb($secondary, 50%) !important;
		}
	}
}