
/* VARIABLES
-------------------------------------------------------------- */

$font-stack: 'Montserrat', sans-serif;
$primary-color-700: #20333a;
$primary-color-600: #2d4550;
$primary-color-500: #4E6E86;
$primary-color-400: #8CA0B0;
$primary-color-300: #abbcc7;
$primary-color-200: #B3C7D2;
$primary-color-100: #f3f7f9;
$accent-color-500: #0095BA;

$sag-renovering: #00b3ff; 		// light blue - sag-1
$sag-infrastruktur: #AC00FF; 	// purple - sag-2
$sag-kapital: #0035b5; 			// navy - sag-3
$sag-husleje: #FF00C7; 			// pink - sag-4
$sag-boligsocial: #FF9B1F; 		// orange - sag-5
$sag-regnskab: #6be600;			// green - sag-6 #00C416
$map-color:rgb(73, 174, 182,1);

$box-shadow: 0 0 10px 0 rgba(62, 70, 82, 0.2);
$main-border: 1px solid lighten($primary-color-300, 15%);
$login-bar-height: 41px;
$header-height: 80px;
$total-header-height: $login-bar-height+$header-height;


/* RESETS & GENERAL APP STYLES
-------------------------------------------------------------- */
*{margin:0;padding:0;font-size:100%;-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}
html,body{color:$primary-color-700;font:14px/20px $font-stack;width:100%;height:100%;-webkit-font-smoothing: antialiased;overflow: hidden;}
img,iframe{border:0;max-width:100%;height:auto;fill:currentColor;vertical-align:middle;}
svg {vertical-align: middle; fill:currentColor;}
ol,ul{list-style:none}
p{margin:0;}
a{color:$accent-color-500; text-decoration: none; transition: 0.3s;}
// a:hover {
// 	color: darken($accent-color-500, 5%);
// 	text-decoration: underline;
// }
:focus {outline: $primary-color-700 thin dotted;}

button {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer !important;
	border-radius: 3px;
	transition: 0.3s;
	&:hover {
		opacity: 0.8;
	}
}

button.main {
	background-color: $primary-color-700; color: #fff;
	height: 40px;
	font-weight: 600;
}

header > button.main {
  margin-left: auto;
  padding: 4px 13px;
  height: 35px;
}

footer, .buttons {
	display: flex;
	bottom: 0;
	height: 64px;
	font-weight: 600;
	justify-content: space-between;
	align-items: center;

	button {
		height: 40px;
		flex-basis: 46%;
	}
}

svg,i {display: inline-block;}

h1, h2, h3, h4 { font-weight: 600;}

// h2 {font-size: 16px;}
// h3 {font-size: 15px;}

// h2, h3 {
// 	display: flex;
// 	span {
// 		color: $primary-color-400;
// 		padding-right: 8px;
// 	}
// 	> div {
// 		padding-right: 8px;
// 		max-width: 92%;
// 		word-wrap: break-word;
// 	}
// 	small {
// 		color: darken($primary-color-300, 10%);
// 		font-size: 13px;
// 		margin-left: auto;
// 		// align-self: flex-start;
// 	}
// }


@import 'forside', 'header', 'panels', 'filter', 'modal', 'loading', 'search', 'responsive';
