.l-sidebar {
	&__header {
		position: relative;
		background-color: #fff;
		// box-shadow: 0 0 15px 0 rgba(24,49,71,0.15);
		// box-shadow: $box-shadow;
		width: 100%;
		top: 0;
		z-index: 1;
		flex-basis: 50px;
		flex: 0 1 auto;
		padding: 0 #{$spacer};
		&--small { // Small header fixed height
			min-height: 4rem; 
		}
		&--details {
			background-color: transparent;
			box-shadow: none;
			// details header
			// padding-bottom: 1em;
			// > div:first-of-type {
			// 	z-index: 10;
			// }
			// &:before {
			// 	content: '';
			// 	position: absolute;
			// 	right: 0;
			// 	left: 0;
			// 	bottom: 0;
			// 	height: 3em;
			// 	background-image: linear-gradient(white, white, rgba(white, 0));
			// 	z-index: 1;
			// }
		}
		h1 {
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	&__content {
		// background-color: $primary-color-100;
		height: inherit;
    	// overflow-y: scroll;
    	overflow-y: auto;
		// height: calc(100% - 4em) // 4em min height of l-sidebar-hedader kan måske laves med flex
		&--details {
			// margin-top: -3em;
			// > div:first-of-type {
			// 	margin-top: 3em;
			// }
		}
	}
	.is-details, .is-departments, &.c-toolbar__panel, .c-toolbar__panel  { // State: Departments, Details (Burde være styret som modifier på l-sidebar fx. l-sidebar--departments, nu er det et child element til l-sidebar)
		height: 100%; // Så childs kan have scroll
		display: flex;
		flex-direction: column;
	}
	.is-departments {
		width: 100%;
		background-color: $primary-color-100;
	}
	.is-details {
		width: 700px;
		background-color: white;
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
	&--end { // Sidebar layout right
		width: $l-sidebar-end-width;
		margin-right: 0;
		@include media-breakpoint-down(md) {
			// position: absolute;
			// width: calc(100% - 4em);
			width: 100%;
			// right: 0;
			// left: 4em;
			// left: 0;
			z-index: 99;
		}
		.cards {
			flex-grow: 1;
		}
		&.details-is-open {
			width: 700px;
			transition: 0.2s cubic-bezier(0.0,0.0,0.2,1);
			@media (prefers-reduced-motion: reduce) {
				transition: none;
			}
		}
		// visible/hidden slider
		// transition: right linear 300ms;
		// &.is-hidden {
		// 	right: -100%;
		// 	left: auto;
		// }
		// &.is-visible {
		// 	right: 0;
		// 	left: auto;
		// }
	}
}