.poi {
    @each $color, $value in $poi-colors {
        &-#{$color} { 
            .form-check-label {
                .c-icon {
                    color: $value;
                }
            }
            .form-check-input {
                &:hover {
                    border-color: $value !important;
                }
                &:checked {
                    background-color: $value !important;
                    border-color: $value !important;
                }
                &:focus {
                    border-color: $value !important;
                }
            }
        }
    }
}

.boligtype {
    @each $color, $value in $boligtype-colors {
        &-#{$color} { 
            .form-check-input {
                &:hover {
                    border-color: $value !important;
                }
                &:checked {
                    background-color: $value !important;
                    border-color: $value !important;
                    + .form-check-label {
                        .c-icon {
                            color: $value;
                        }
                    }
                }
                &:focus {
                    border-color: $value !important;
                }
            }
        }
    }
}