h1 {
    // color: red; 
}

.h6, h6 {
    font-weight: 600;
}


.c-subheader { //anbefalet h2
    align-items: center;
    display: flex;
    height: 48px;
    font-size: .875rem;
    // font-weight: 400;
    font-weight: 600;
    padding: 0 16px;
}