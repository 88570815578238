.c-filter-tags {
	// max-height: 0;
	visibility: hidden;
	transition: 0s;
	border-bottom: 2px solid #e9f0f4;
	&.is-open {
		transition: all 0s 0.2s;
		// max-height: 500px;
		visibility: visible;
	}
	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
	.badge {
		background-color: transparent;
		color: $secondary;
		font-weight: 600 !important;
		padding: 0.45em 0.65em !important;
		font-size: .9em;
		margin-right: .25em;
		margin-bottom: .5em;
	}
}