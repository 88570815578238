.c-list {
    &__item {
		border: 0;
        min-height: 80px;
		flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: $border-radius;
		padding: #{$spacer} #{$spacer * 1.25};
		box-shadow: $box-shadow;
        cursor:pointer;
        // &.has-hover, &:hover {
		// 	border-color: $primary;
        // }
		&--loadmore {
			display: block;
			background: transparent;
			border: none;
			box-shadow: none;
			height: auto;
			color: #748c9f;
			font-weight: 500;
			font-size: 15px;
			text-align: center;
			margin: 0;
			border-color: transparent !important;
			&:before {
				content: "";
				width: 100%;
				position: absolute;
				z-index: 100;
				left: 0;
				top: -100px;
				height: 100px;
				background: linear-gradient(rgba(243, 247, 249, 0), #f3f7f9);
				pointer-events: none;
			}
		}
		&--inverted {
			
		}
    }
	&--spaced {
		.c-list__item {
			border: 1px solid white;
			margin-bottom: #{$spacer};
			// box-shadow: $box-shadow;
			&.has-hover, &:hover {
				border-color: $primary;
			}
		}
	}
	&--narrow {
		.c-list__item {
			min-height: 50px;
			// padding: #{$spacer} #{$spacer * 1.25};
			flex-direction: initial;
		}
	}
}

// .c-list { //gammel
// 	.cards, .loading-cards {

// 		.card {
// 			height: 80px;
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: space-between;
// 			border-radius: 3px;
// 			cursor:pointer;
// 			h2 {
// 				div, span { transition: color 0.2s; }
// 				&:hover {
// 					> div {
// 						color: darken($primary-color-700, 20%);
// 					}
// 					span {
// 						color: darken($primary-color-400, 15%);
// 					}
// 				}
// 			}
// 		}
// 		.card.no-click {
// 			background: transparent;
// 			border:none;
// 			box-shadow: none;
// 			height: auto;
// 			color: darken($primary-color-400, 8%);
// 			font-weight: 500;
// 			font-size: 15px;
// 			text-align: center;
// 			margin: 0;
// 			&:before {
// 				content: "";
// 				width: 100%;
// 				position: absolute;
// 				z-index: 100;
// 				left: 0;
// 				top: -100px;
// 				height: 100px;
// 				background: linear-gradient(rgba(243, 247, 249, 0), $primary-color-100);
// 			}
// 			h4 {
// 				margin-bottom: 1em;
// 			}

// 			button {
// 				width: 60%;
// 				margin: 0 auto;
// 			}
// 			&:hover {
// 				border: none;
// 			}
// 		}
// 	}
// 	header {
// 		.btn-collapse {
// 			position: fixed;
// 			top: $total-header-height;
// 			z-index: 1000;
// 			transform: none;
// 			// display: none;
// 		}
// 	}
// }