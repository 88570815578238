.c-signatur--new {
    background-color: $light;
    display: none;
    font-weight: 500;
    font-size: 0.95em;

    ul {
        padding: 0 #{$spacer * 0.5} $spacer #{$spacer * 2.5};
        li {
            padding: #{$spacer * 0.5} 0;
            display: flex;
            align-items: center;
            gap: #{$spacer * 0.5};
            line-height: normal;
            > ul {
                padding: 0;
                li {
                    padding: #{$spacer * 0.25} 0;
                }
            }
        }
    }
    .signatur {
        &-box {
            width: 14px;
            height: 14px;
            border-width: 2px;
            border-style: solid;
            .fill {
                display: block;
                width: 100%;
                height: 100%;

            }

            @each $color, $value in $lokalplan-colors {
                &.lokalplan-#{$color} { 
                    border-color: $value;
                    .fill {
                        background-color: rgba($value, 0.7);
                    }
                }
            }
        }

        &-line {
            width: 18px;
            height: 1px;
            border-width: 3px 0 0 0;
            border-style: solid;

            &.matrikelskel {
                border-color: #FD3030;
            }
        }

        &-dashed {
            border-style: dashed;
        }
    }
    
}