

/*
*
* PANELS : Sidebar END
*
*/

/* TO BE RESTRUCTURED INTO COMPONENTS....
-------------------------------------------------------------- */

.c-list, .c-list-details {
	height: 100%;
}

// .c-list {
//     width: 430px;
// }

.c-list-details {
    width: 50vw;
}

/* CARDS
--------- */
.cards,
.loading-cards {
	padding: 0 15px;
  overflow-y: auto;
  display: block;
}

.card,
.loading-card {
	margin: 15px 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 12px 0 rgba(24,49,71,0.1);
	border: 1px solid #fff;
	padding: 16px 20px;
	position: relative;
}
.cards {
	.card {
		transition: border 0.3s;
		font-weight: 500;
		&:hover, &.hover {
			border: 1px solid $map-color;
		}

		ul {
			display: flex;
		}
	}

	.card.active {
		border: 1px solid $map-color;
	}

	h3, h2 {
		> div {
			max-width: 90%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		small {
			margin-left: 0;
		}
	}
}

// .favorite {
// 	padding: 0 4px;
// 	margin-left: auto;
// 	margin-right: 6px;
// 	color: darken($primary-color-300, 10%);
// 	transition: 0s;
// 	opacity: 0.7;
// 	&.active {
// 		color: $text-pink;
// 		// color:darkmagenta;
// 	}
// 	&:hover {
// 		color: $text-pink;
// 		// color: darkmagenta;
// 		opacity: 1;
// 	}
// 	svg {
// 		width: 20px;
// 		height: 20px;
// 	}
// }


.table {
	padding-bottom: 28px;
	flex-direction: column;
	li {
		padding: 10px 14px;
		overflow: hidden;
		> div {
			float: left;
			width: 50%;
		}
		&:nth-child(odd) {
			background-color: $primary-color-100;
		}
	}
	h4 {
		font-size: 13px;
		font-weight: 500;
		color: $primary-color-400;
	}
	p {
		margin: 2px 0;
		font-size: 15px;
		font-weight: 500;
		small {
			color: $primary-color-400;
		}
	}
}

.box {
	position: relative;
	> .slide {
			padding: 16px 10px;
		}
	> h4 {
		padding: 16px 10px;
		color: $primary-color-400;
		border-top: $main-border;
		font-weight: 600;
		transition: color 0.3s;
	}

	.box-cont {
		max-height: 0;
    	transition: all 0.2s ease;
    	overflow: hidden;
    	> * {
    		padding: 12px 10px 30px 10px;
		}
		ul.bbr {
			display: block;
		}

		figure.eksempel {
			width: 100%;
			height: 290px;
			background-image: url(/img/graf-eksempel.svg);
			background-repeat: no-repeat;
			background-size: 90%;
			background-position: center 25px;
		}
	}
}

.box.open {
	> .box-cont {
		max-height: 3000px;
		transition: all 1s ease;
	}
}

.close {
	padding: 10px;
	color: $primary-color-300;
	&:hover {
		opacity: 1;
		color: $primary-color-700;
	}
}

// .c-list {
// 	background-color: $primary-color-100;
// 	.cards, .loading-cards {

// 		.card {
// 			height: 80px;
// 			display: flex;
// 			flex-direction: column;
// 			justify-content: space-between;
// 			border-radius: 3px;
// 			cursor:pointer;
// 			h2 {
// 				div, span { transition: color 0.2s; }
// 				&:hover {
// 					> div {
// 						color: darken($primary-color-700, 20%);
// 					}
// 					span {
// 						color: darken($primary-color-400, 15%);
// 					}
// 				}
// 			}
// 		}
// 		.card.no-click {
// 			background: transparent;
// 			border:none;
// 			box-shadow: none;
// 			height: auto;
// 			color: darken($primary-color-400, 8%);
// 			font-weight: 500;
// 			font-size: 15px;
// 			text-align: center;
// 			margin: 0;
// 			&:before {
// 				content: "";
// 				width: 100%;
// 				position: absolute;
// 				z-index: 100;
// 				left: 0;
// 				top: -100px;
// 				height: 100px;
// 				background: linear-gradient(rgba(243, 247, 249, 0), $primary-color-100);
// 			}
// 			h4 {
// 				margin-bottom: 1em;
// 			}

// 			button {
// 				width: 60%;
// 				margin: 0 auto;
// 			}
// 			&:hover {
// 				border: none;
// 			}
// 		}
// 	}
// 	header {
// 		.btn-collapse {
// 			position: fixed;
// 			top: $total-header-height;
// 			z-index: 1000;
// 			transform: none;
// 			// display: none;
// 		}
// 	}
// }

.facts {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 500;
	li {
		cursor: auto;
		button span, button i {color: $primary-color-500; transition: 0.2s;}
		button i {color: $primary-color-400;}
		button {line-height: 100%;}
		button:hover span, button:hover i { color: $accent-color-500;}
		button:hover span { text-decoration: underline;}
		svg {width: 15px; height: 12px; margin-top: -2px;}
		i {padding-right: 5px; color: $primary-color-400; transition: 0.2s; }
	}
	li:first-child {
		flex-basis: 65%;
		max-width: 65%;

		button {
			max-width: 100%;
			line-height: 110%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

		}

	}
}

// .c-list, .tabs, .sags-link {
// 	.sag-0 {background-color: $map-color;}
// 	.sag-1 {background-color: $sag-renovering;}
// 	.sag-2 {background-color: $sag-infrastruktur;}
// 	.sag-3 {background-color: $sag-kapital;}
// 	.sag-4 {background-color: $sag-husleje;}
// 	.sag-5 {background-color: $sag-boligsocial;}
// 	.sag-6 {background-color: $sag-regnskab;}
// 	.sager {
// 		position: relative;
// 		flex-basis: 10%;
// 		list-style: none;
// 		i {
// 			position: absolute;
// 			top: 4px;
// 			right: 0;
// 			width: 14px;
// 			height: 14px;
// 			border-radius: 50%;
// 			border: 2px solid #fff;
// 		}
// 		i:nth-child(2) {
// 			right: 8px;
// 		}
// 		i:nth-child(3) {
// 			right: 16px;
// 		}
// 		i:nth-child(4) {
// 			right: 24px;
// 		}
// 		i:nth-child(5) {
// 			right: 32px;
// 		}
// 		i:nth-child(6) {
// 			right: 40px;
// 		}
// 	}
// }

.links a {
	border-radius: 3px;
}

.sag-1.selected .checkbox {color: $sag-renovering;}
.sag-2.selected .checkbox {color: $sag-infrastruktur;}
.sag-3.selected .checkbox {color: $sag-kapital;}
.sag-4.selected .checkbox {color: $sag-husleje;}
.sag-5.selected .checkbox {color: $sag-boligsocial;}
.sag-6.selected .checkbox {color: $sag-regnskab;}

.close-view .l-sidebar-start {
	margin-left: -200px;
}

.close-list .l-sidebar-end, .close-list .placeholder-panel {
	margin-right: -370px;
}

.close-view .l-sidebar-start,
.close-list .l-sidebar-end {
	.btn-collapse svg {
		transform: rotate(180deg);
	}
	.cont > *:not(header) {
		opacity: 0;
		pointer-events: none;
	}
}

/* DETAIL PANEL
-------------------------------------------------------------- */
.c-list-details {
	h2 {
		font-size: 18px;
	}

	.box {
		&:hover > h4 {
			color: $primary-color-700;
		}
	}

	.open {
		> .slide, > h4 {
			color: $primary-color-700;
		}
	}

	header {
		text-transform: none;
		box-shadow: none;
		height: auto;
		padding: 0;
		line-height: 200%;

		.heading {
			padding: 20px 60px 15px 20px;
		}
		small {
			padding-right: 12px;
		}
	}

	.close {
		position: absolute;
		top:0;
		right: 0;
		padding: 11px 20px;
	}

	.tabcontent {
		min-height: 0;
		overflow-y: auto;
		flex-grow: 1;

	}

	.tabcontent.afdeling {
		border-top: 20px solid #ffffff;
		padding: 0 20px 20px 20px;
		background-color: #ffffff;
	}

	.tabcontent.afsnit {
		background-color: $primary-color-100;
		border-top: 15px solid $primary-color-100;
		.c-list .cards {
			margin-top: 0;
			.card {
				height: auto;
				&:hover, &.hover {
					border: 1px solid $primary-color-700;
				}
			}
		}

		.card.box > .slide {
			padding: inherit;
		}

		.box .box-cont {
			> * {
				padding: 0;
			}

			.bbr {
				padding: 12px 10px 0 10px;
			}

			.table {
				padding: 28px 0;
			}
		}

	}

	.afdeling-active > .tabcontent.afdeling {
		display: flex;
	}
	.afsnit-active > .tabcontent.afsnit {
		display: block;
	}

	.tabs {
		border-top: $main-border;
		display: flex;
		.tab {
			width: 50%;
			height: 52px;
			padding: 0 10px 0 20px;
			border-radius: 0;
			text-align: left;
			color: darken($primary-color-400, 5%);
			display: flex;
			align-items: center;
			h3 {
				font-weight: 600;
			}
			&:hover,
			&.active {
				color: $primary-color-700;
				opacity: 1;
			}
		}
		.tab {
			background-color: $primary-color-100;
			outline: none;
			h3 i {
				font-style: normal;
				background-color: #fff;
				width: 28px;
				height: 28px;
				border-radius: 50%;
				text-align: center;
				margin-left: 10px;
			}
		}
		.tab-afdeling {
			background-color: #fff;
		}
		.sager {
			margin-left: auto;
			margin-top: 3px;
		}
  }

	.afdeling-active .tab-afdeling {
		background-color: white;
		color: $primary-color-700;
	}
	.afsnit-active .tab-afsnit {
		background-color: $primary-color-100;
		color: $primary-color-700;
  }

	.sags-link {
		display: flex;
		height: 46px;
		align-items: center;
		background: $primary-color-100;
		border-radius: 18px 4px 4px 18px;
		overflow: hidden;
		font-weight: 500;
		margin-bottom: 20px;

		.label {
			width: 46px;
			line-height: 46px;
			text-align: center;
			height: 100%;
			border-radius: 50%;
			color: #ffffff;
			font-weight: 700;
			font-size: 18px;
			margin-right: 12px;
			flex-shrink:0;
		}

		small {
			color: $primary-color-400;
			font-size: 13px;
		}

		a {
			margin-left: auto;
			color: $primary-color-600;
			background: darken($primary-color-100, 3%);
			padding: 0 14px;
			border-left: 2px solid #ffffff;
			height: 100%;
			display: flex;
			align-items: center;
			flex-shrink: 0;
			svg {
				width: 18px;
			}
			&:hover {
				color: $primary-color-700;
				background: lighten($primary-color-200, 12%);
			}
		}
	}

	.bbr .sags-link .label {
		font-size: 13px;
	}

	.links {
		a {
			display: inline-block;
			padding: 10px 14px;
			background-color: $primary-color-100;
			color: $primary-color-700;
			font-weight: 500;
			margin: 4px 2px;
			font-size: 15px;
			text-decoration: none;
			transition: 0.2s;

			// .icon {
			// 	display: inline-block;
			// 	width: 30px;
			// 	height: 32px;
			// 	margin-right: 8px;
			// 	margin-top: -2px;
			// 	vertical-align: middle;
			// 	background-size: auto 30px;
			// 	background-position: center center;
			// 	background-repeat: no-repeat;

			// 	&.driftstoette { background-image: url(/img/driftstoette.svg);}
			// 	&.tvilling { background-image: url(/img/tvilling.svg);}
			// 	&.ledigeboliger { background-image: url(/img/ledigeboliger.svg); background-size: auto 32px;}
			// 	&.regnskab { background-image: url(/img/regnskab.svg);}
			// 	&.stamdata { background-image: url(/img/stamdata.svg);}
			// }

			&:hover {
				background-color: lighten($primary-color-200, 15%);
			}
			> div {
				display: inline;
			}
		}

		img {
			mix-blend-mode: multiply;
			opacity: 0.9;
		}

		svg {
			width: 18px;
			height: 18px;
			margin-top: -3px;
			margin-left: 4px;
		}
	}
}
