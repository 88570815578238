// body, html {
//     height: 100%;
// }

// Panel sizes

$c-toolbar__menu-width: 70px;
$c-toolbar__panel-width: 280px;
$l-sidebar-start-width: $c-toolbar__menu-width + $c-toolbar__panel-width;
$l-sidebar-end-width: 430px;

.l-sidebar,
.l-map-ui {
	height: 100%;
	z-index: 1;
}

$icon-size: (
  "size-n2": 0.25,
  "size-n1": 0.5,
  "size": 1,
  "size-1": 1.5,
  "size-2": 1.75,
  "size-3": 2.25
);
.c-icon {
	display: inline-block;
	@each $size, $value in $icon-size {
		&--#{$size} {
			svg {
				height: #{1em * $value} !important;
				width: auto !important;
			}
		}
	}
	.ember-view { //ember icons  &.ember-view 
		svg {
			height: 1em;
			width: auto;
		}
	}
}

.c-data-organisation {
	color: tint-color($secondary, 25%);
	font-weight: 600;
	text-align: left;
	> .c-icon {
		padding-right: .35em;
		// color: tint-color($secondary, 60%);
		svg {
			height: .85em;
    		margin-top: -.15em;
		}
	}
	&:hover:not([disabled]) {
		color: $primary;
		text-decoration: underline;
		> .c-icon {
			color: $primary;
		}
	}
	&:focus:not([disabled]) {
		outline: 0;
		box-shadow: 0 0 0 0.25rem rgb($secondary, 25%);
	}
	&[disabled] {
        pointer-events: none;
    }
}

// .progress {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// 	left: 0;
// 	height: 4px;
// }
// .l-content {
// 	@include media-breakpoint-down(md) {
// 		height: calc(100vh - 210px) !important;
// 	}
// }
.c-mobile-bottom-nav {
	position: fixed;
	bottom: 0;
	//øvre er blot til test og skal fjernes
	width: 100%;
	z-index: 999;
	@include media-breakpoint-up(md) {
		display: none;
	}
}
.c-loading-toast  {
	width: initial;
    max-width: initial;
    display: inline-flex;
	padding: 0 $spacer;
	font-weight: 600;
	position: absolute;
	bottom: 2.5em;
	left: 50%;
	transform: translateX(-50%);
	z-index: 99;
	box-shadow: none;
	background-color: rgba($secondary, .75) !important;
	backdrop-filter: blur(.25rem);
}

// .c-sidebar-minify {
// 	margin-top: $spacer;
// 	box-shadow: $box-shadow;
// 	padding: 2em .25em;
// 	&--end {
// 		border-radius: 0 $border-radius $border-radius 0;
// 		margin-left: -$spacer;
// 		position: absolute;
// 		z-index: 50;
// 		top: 1rem;
// 		right: 100%;
// 	}
// 	&--start {
// 		border-radius: $border-radius 0 0 $border-radius;
// 		margin-right: -$spacer;
// 	}
// }

.c-data-lbfnummer {
	color: tint-color($secondary, 25%);
	font-weight: 600;
}

.favorite { //steder med denne bør skiftes til en normal knap (fra old)
	padding: 0 4px;
	margin-left: auto;
	margin-right: 6px;
	// color: darken($pink, 10%);
	transition: 0s;
	opacity: 0.7;
	&.active {
		color: $pink;
	}
	&:hover {
		color: $pink;
		opacity: 1;
	}
	svg {
		width: 20px;
		height: 20px;
	}
}


.septimasearch { //septima search specifc
	.ssInput {
		&:focus {
			outline: none !important;
			box-shadow: none !important;
		}
	}
	&.ssActive {
		.ssSearchBackground {
			box-shadow: 0 0 0 0.25rem rgb($primary, 50%) !important;
		}
	}
}

//Filtrér i kort dropdowns
.ember-power-select-trigger {
	min-height: 3.25rem;
	display: flex; align-items: center;
	@extend .form-control;
	padding: 0.375rem 1.75rem .375em .75em;
	&--active {
		box-shadow: 0 0 0 .25rem rgba($primary, .5);
	}

	.ember-power-select-multiple-options { //ul
		.ember-power-select-multiple-option  {
			// border: 2px solid $secondary;
			@extend .badge;
			// // background-color: rgba($secondary, 1);
			background-color: transparent;
			color: $secondary;
			font-weight: 600 !important;
			padding: 0.45em 0.65em !important;
			font-size: .9em;
			.ember-power-select-multiple-remove-btn {
				color: $secondary;
				opacity: 1;
				height: .75em;
				width: .75em;
				display: inline-block;
				border-radius: 2px;
				line-height: 9px;
				margin: 3px 4px 0 0;
				transform: scale(1.75);
				&:hover {
					background-color: tint-color($secondary, 90%);
					color: $secondary;
				}
			}
		}
	}
	.ember-power-select-status-icon { //dropdownicon
		right: 10px;
	}
	.ember-power-select-trigger-multiple-input { // ul > input
		box-shadow: none !important;
		height: 30px;
		margin-left: 5px;
		&::-webkit-search-cancel-button {
			/* Remove default */
			-webkit-appearance: none;
			/*Your new styles */
			height: 12px;
			width: 12px;
			background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 49.4 49.39'%3E%3Cpath d='M25.15.07a24.7,24.7,0,1,0,24.7,24.69A24.69,24.69,0,0,0,25.15.07ZM38,32.36A3.61,3.61,0,0,1,39.08,35,3.69,3.69,0,0,1,38,37.61a3.79,3.79,0,0,1-5.24,0L25.15,30l-7.6,7.6a3.72,3.72,0,0,1-5.24,0,3.69,3.69,0,0,1,0-5.26l7.59-7.59-7.61-7.6a3.7,3.7,0,1,1,5.25-5.22l7.59,7.59,7.6-7.6a3.67,3.67,0,0,1,5.25,0,3.62,3.62,0,0,1,1.08,2.6A3.66,3.66,0,0,1,38,17.17l-7.59,7.59Z' transform='translate(-0.45 -0.07)' fill='%232d4450'/%3E%3C/svg%3E%0A");
			background-size: cover;
		  }
	}
}
.ember-basic-dropdown-content { //ul dropdown
	border: 0 !important;
	box-shadow: $box-shadow;
	.ember-power-select-option { //li dropdown
		padding: 4px $spacer;
	}
}
