


// DENNE KOMPONENT SKAL FJERNES



// TO BE RESTRUCTURED INTO COMPONENTS...

.l-sidebar, .signatur { //dette tilhører sig styling under l-sidebar--details
	.slide {
		cursor: pointer;
		padding: inherit;
		position: absolute;
		width: 100%;
		text-align: right;
		top: 0;
		right: 0;
		z-index: 10;
		color: $primary-color-400;
		transition: 0.3s;
		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
		&:hover {
			color: $primary-color-600;
		}
		svg {
			width: 13px;
			height: 13px;
		}
		.minus {
			display: none;
		}
		.plus {
			display: inline-block;
		}
	}

	.slide.icon {
		padding: 10px 16px;
		width: auto;
	}

	.open {
		> .slide,
		> .label-1 > .slide {
			.plus {
				display: none;
			}
			.minus {
				display: inline-block;
			}
		}
	}
}