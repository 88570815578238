/* HEADER
-------------------------------------------------------------- */
.l-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 102;
	width: 100%;
}

.main-header {
	background-color: #ffffff;
	width: 100%;
	height: $header-height;
	border-bottom: $main-border;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}

.logo {
	height: 42px;
	display: flex;
	align-items: center;
	cursor: pointer;
	
	svg,
	.service-name {
		display: inline-block;
	}
	.service-name {
		color: $primary-color-700;
		padding-left: 10px;
		padding-top: 3px;
		font-family: "Montserrat", Arial, Helvetica, sans-serif;
		letter-spacing: 0.02em;
		font-weight: 600;
		line-height: 100%;
		font-size: 18px;
	}
}

.settings {
	width: 45px;
	height: 45px;
	background-color: darken($primary-color-100, 2%);
	opacity: 0.8;
	border-radius: 50%;
	color: $primary-color-600;
	margin-left: 1em;
	&:not(.share) svg {
		width: 17px;
		height: 17px;
	}

	svg {
		margin-top: -4px;
	}
	&:hover { 
		opacity: 1;
		color: $primary-color-700;
	}
}




