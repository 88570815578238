.c-case-bits {
	display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
	min-height: 23px;
	.sager {
		position: relative;
		flex-basis: 10%;
		list-style: none;
		i {
			position: absolute;
			top: 4px;
			right: 0;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			border: 2px solid #fff;
			@each $color, $value in $case-colors {
				&.#{$color} { // .case-0, .case-1
					background-color: $value !important;
				}
			}
			&:nth-child(2) {
				right: 8px;
			}
			&:nth-child(3) {
				right: 16px;
			}
			&:nth-child(4) {
				right: 24px;
			}
			&:nth-child(5) {
				right: 32px;
			}
			&:nth-child(6) {
				right: 40px;
			}
		}
	}

}