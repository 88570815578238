.fw-semi-bold {
    font-weight: 600;
}

.bg { //tint BG: .bg-tint-primary
    @each $color, $value in $theme-colors {
        &-tint-#{$color} {
            background-color: tint-color($value, 90%) !important;
        }
    }   
}

// text color renderes forkert
.text {
	@each $color, $value in $theme-colors {
		&-#{$color} {
			color: $value !important;
		}
	}
}

.text-muted {
    // color: #6c757d !important;
    color: tint-color($secondary, 25%) !important;
}

hr.divider {
    // color: red;
    color: tint-color($primary, 50%);
    display: block;
    flex: 1 1 0px;
    max-width: 100%;
    height: 0;
    max-height: 0;
    border: solid;
    border-width: thin 0 0;
    transition: inherit;

    &--light {
        width: 100%;
        color: $border-color;
        opacity: 1;
    }
}

.spinner-border {
    &-sm {
        border-width: 0.23em;
        animation-duration: 1.5s;
        opacity: .5;
    }
}