.c-metadata {
	line-height: 1.4em;
	&-title {
		font-size: .9285em;
		display: block;
		font-weight: 500;
		// color: tint-color($secondary, 27%);
		// color: $secondary;
		// color: $primary;
		// color: black;
		color: tint-color($secondary, 25%);
		margin-bottom: .15em;
	}
	&-text {
		// margin: 2px 0;
		font-size: 1.075em;
		font-weight: 500;
		color: tint-color($dark, 25%);
	}
	&--justify {
		display: flex;
		align-items: center;
		flex-flow: wrap;
	}
}