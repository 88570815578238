* {
	&:focus {
		outline: 0 !important;
		box-shadow: none !important;
	}
	&:focus-visible {
		box-shadow: 0 0 0 0.25rem rgb($primary, 50%) !important;
	}

}

.c-skip-to-content {
	z-index: 9999;
	position: fixed;
	top: 1em;
	left: 2em;
	padding: .25em 1.25em !important;
	// background-color: $dark;
	// border-radius: $border-radius;
	transition: none;
	// color: $white;
	// font-weight: 600;
	small {
		color: shade-color($white, 25%);
		font-weight: 500;
	}
	// &:hover {
	// 	color: $white;
	// }
}