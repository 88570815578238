.c-avatar {
    // justify-content: center;
    // align-items: center;
    // display: inline-flex;
    // min-height: 2rem;
    // min-width: 2rem;
    // border-radius: 10em;
    // // padding: .5em;
    // background-color: red;

    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    line-height: normal;
    position: relative;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    font-weight: 500;

    background-color: tint-color($dark, 90%);

    height: 2.75em;
    min-width: 2.75em;
    width: 2.75em;
    
    font-size: 16px;

    @each $color, $value in $theme-colors {
        &.bg-#{$color} {
            background-color: $value !important;
            color: text-color($value) !important;
        }
    }

    &--text {
        font-weight: 700;
    }
    svg {
        width: 1.25em;
        height: auto;
    }

}