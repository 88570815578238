.btn-group {
    &--spaced {
        .btn {
            margin-right: calc($spacer / 2);
            border-radius: $border-radius !important;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &--app-bar {
        .btn {
            margin-right: .25em;
            border-radius: 0 !important;
            line-height: 1;
            border: 0;
            border-top: .25em solid transparent;
            padding-top: .65em;
            padding-bottom: .75em;
            svg {
                height: 1.5em;
                width: auto;
            }
            small { //label i btn
                display: block;
                line-height: 1;
                margin-top: .5em;
                font-weight: 500;
                font-size: .75em;
            }
            &.active {
                // background-color: shade-color($light, 25%);
                // border-color: shade-color($light, 25%);
                color: $primary;
                border-color: $primary;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.btn-group-vertical {
    &--spaced {
        .btn {
            margin-bottom: calc($spacer / 2);
            border-radius: $border-radius !important;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
