.l-content {
  height: calc(100vh - #{$total-header-height});
  margin-top: $total-header-height;
  position: relative;
  display: flex;
  flex-direction: column;
  > .row {
    @include media-breakpoint-down(md) {
      height: calc(100% - 59px) !important; //bør laves bedre hænger sammen med .c-mobile-bottom-nav
    }
  }
}