/* FORSIDE
-------------------------------------------------------------- */

.almen-gis-front {
	font-family: "Open Sans",Arial,sans-serif;
	background-color: #f1f1f1;
	width: 100%;
	height: 100vh;

	header {
		position: relative;
	}

	.button {
		height: 40px;
		padding: 0 18px;
		line-height: 40px;
		border: none;
		border-radius: 2px;
		background-color: shade-color(#32a674, 20%);
		color: #fff;
		text-decoration: none;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
		cursor: pointer;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		transition: box-shadow .2s;
	}

	.button.service-header-help-button {
		right: 25px;
		top: 20px;
		padding: 0 16px;
	}

	.main-content {
		position: absolute;
		left: 0;
		top: 120px;
		right: 0;
		bottom: 0;
		overflow: auto;
		background: url(/img/logo-large-outline.png) right bottom no-repeat;
	}

	.intro-view {
		margin: 3% 0;
		> .editor-content {
			max-width: 900px;
			margin: 0 auto;
			padding: 60px;
			background-color: rgba(255,255,255,0.8);
		}

		.no-padding {
			padding: 0;
			background-color: transparent;
		}

		.intro-box {
			background: #2D4551;
			background: white;
			color: #2D4551;
			padding: 40px 60px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 2vh;
			background-color: rgba(255,255,255,0.8);
		}
		.text-content {
			padding: 20px 60px 60px;
			background-color: rgba(255,255,255,0.8);
		}

		h1 {
			font-family: "Montserrat", sans-serif;
			font-size: 26px;
			font-weight: 600;
			line-height: 140%;
			color: inherit;
			margin-right: 1em;
		}
		h2 {
			font-size: 20px;
			font-weight: 300;
			margin-top: 32px;
			margin-bottom: 14px;
			line-height: 120%;
    }

    ul {
			font-size: 16px;
			color: #767676;
      		list-style: inside;
			margin: 20px;
			li {
				list-style-image: url(https://lbf.dk/css/images/list-image.png);
				line-height: 1.467em;
				margin: 0.7em 0;
				}
			}


		p {
			font-size: 16px;
			margin-bottom: 10px;
			line-height: 150%;
			color: #767676;
    }

		p.intro-view-buttoncontainer {
		font-size: 10px;
		margin: 0;
		.button {
			font-size: 17px;
			height: 45px;
			line-height: 45px;
			padding: 0 24px;
		}
		}
	}
}

.login-bar {
	background-color: #2d4550;
	color: #fff;
	height: $login-bar-height;
	overflow: hidden;
	padding: 0 20px;
}

.login-bar-logo-box {
	float: left;
}

.login-bar-link {
	font-size: 12px;
	height: $login-bar-height;
	line-height: $login-bar-height;
	overflow: hidden;
	font-weight: 600;
	color: #fff;
	margin-right: 33px;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
}

.login-bar-user {
	float: right;
	margin-right: 0;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: right;
	&:hover {
		color: #fff;
	}
}

.login-bar-lbf-logo {
	margin: 6px 8px 0 0;
	float: left;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	width: 196px;
}

@media only screen and (max-width: 959px) {
	.almen-gis-front .intro-view {
		margin-top: 0;

		> .editor-content {
			margin: 20px;
			padding: 40px;

			&.no-padding {
				padding: 0;
			}
		}

		.intro-box, .text-content {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
}

@media only screen and (min-width: 319px) and (max-width: 767px) {
	.almen-gis-front .intro-view {
		.intro-box {
			padding: 20px 30px;
			flex-direction: column;
			h1 {
				font-size: 1.5em;
				margin: 0 0 1em 0;
			}
		}
		.text-content {
			padding: 5px 30px 30px;
		}
	}
 }

