.btn {
    font-weight: 600;
    padding: 0 2.25em;
    min-height: 2.75em;
    line-height: 2.75em;
    > .ember-view, > .c-icon { //Ember icons
        display: inline-block;
        // height: .95em;
        margin-right: .35em;
        svg {
            // height: inherit;
            // margin-top: -.15em;
            margin-top: -.2em;
            height: 1.35em;
            width: auto;
        }
    }
    &-sm { //bootstrap modifier
        padding: 0 .65rem;
        min-height: 1.85rem;
        line-height: 1.85rem;
        > .c-icon {
            svg {
                height: 1em;      
            }
        }
    }
    &-outline {
        @each $color, $value in $theme-colors {
            &-#{$color} { 
                color: shade-color($value, 5%)
            }
        }
    }
    &--icon {
        // background-color: transparent;
        // border-color: transparent !important;
        border-radius: $border-radius; 
        min-height: 2.75em;
        line-height: 2.75em;
        min-width: 2.858em;
        padding: 0;
        > .c-icon { //Ember icons
            margin-right: 0;
            svg {
                height: inherit;
                margin-top: -.15em;
            }
        }
    }
    &--icon-text {
        padding-left: #{$spacer * .75};
        padding-right: #{$spacer * 1.15};
        min-height: 2.5em;
    }
    &-tint { //ny tint baggrundsfarve
        @each $color, $value in $theme-colors {
            &-#{$color} {
                color: text-color($value);
                background-color: tint-color($value, 50%);
                // &:hover {
                //     background-color: tint-color($value, 90%) !important;
                //     color: shade-color($value, 50%);
                // }
                // &:focus {
                //     // background-color: transparent;
                // }
                // &.active {
                //     background-color: $value;
                //     color: text-color($value);
                //     svg {
                //         color: text-color($value);
                //     }
                //     &:hover {
                //         background-color: tint-color($value, 10%) !important;
                //     }
                // }
            }
        }
    }
    &--transparent { //modificering af bootstrap style
        background-color: transparent;
        border: transparent;
        &.btn {
            @each $color, $value in $theme-colors {
                &-#{$color} {
                    color: $value;
                    &:hover, &:focus {
                        background-color: tint-color($value, 90%);
                    }
                }
            }
        }
    }
    &.active { //modificering af bootstrap style
        &.btn {
            @each $color, $value in $theme-colors {
                &-#{$color} {
                    color: text-color($value);
                    background-color: $value;
                }
            }
        }
    }

    &.has-tooltip { // tooltip-popup
        position: relative;
        &:hover:before,
        &:hover:after {
            display:block;
        }
        &:before {
            content: attr(data-tooltip);
            display: none;
            background: #fff;
            position: absolute;
            top: -2.5em;
            white-space: nowrap;
            padding: 0 0.5em;
            left: 50%;
            transform: translateX(-60%);
            border-radius: 4px;
            box-shadow: $box-shadow;
            line-height: 2em;
            color: $secondary;
        }
        &:after { // chevron
            content: "";
            display: none;
            position: absolute;
            top: -8px;
            left: 50%;
            transform: translateX(-50%);
            border: 8px solid transparent;
            border-top-color: #fff;
        }
        &.tooltip {
            &-bottom {
                &:before {
                    top: 100%;
                }
                &:after {
                    top: auto;
                    bottom: 0;
                    border-top-color: transparent;
                    border-bottom-color: #fff;
                }
            }
            &-wrap {
                &:before {
                    white-space: normal;
                    max-width: 200px;
                    line-height: 1.5em;
                    padding: 0.7em;
                }
            }
        }
    }
}