/* SEPTIMA SEARCH
-------------------------------------------------------------- */

.search-cont {

	display: block;
	height: 46px;
	margin-left: -150px;

	.septimasearch {
		display: block;
		width: 550px;
		border: none;
		outline: none;
		background-color: #eef3f5;
		border-radius: 4px;
		font-weight: 500;
		// transition: box-shadow 0.3s;

		.ssCloseButton, .ssSearchIcon, .ssSpinner, input.ssInput, .ssSearchBackground {
			height: 46px;
			line-height: 46px;
		}

		.ssSearchIcon {
			background-image: url(/img/search-icon.svg);
			background-size: 22px;
		}

		.ssSearchBackgroundHasContent .ssCloseButton {
			background-image: url(/img/close-icon.svg);
			background-size: 18px;
		}

		.ssSpinner > * {
			background-color: #ffffff;
		}

		.ssTargetbit {
			background-color: transparent;
			margin-left: 4px;
			white-space: nowrap;
			> * {
				background-color: $map-color;
				font-size: 15px;
				font-weight: 500;
				vertical-align: top;
			}
			.ssTargetclosebutton {
				background-size: 20px;
				background-position: left center;
			}
		}
		input.ssInput {
			color: $primary-color-700;
			font-family: $font-stack;
			font-weight: inherit;
			font-size: 17px;
			padding: 0 24px 0 10px;

			&::placeholder {
				color: #748c9f;
			}
						  
			&:-ms-input-placeholder {
				color: #748c9f;
			}
						  
			&::-ms-input-placeholder {
				color: #748c9f;
			}
		}

		&.ssActive {
			box-shadow: none;
			box-shadow: $box-shadow;
			background-color: #ffffff;
		}

		.ssSearchResult {
			border: none;
			// border-top: $main-border;
			background-color: #ffffff;
			font-size: 16px;
			max-height: 337px;
			overflow: auto;
			border-radius: 0 0 4px 4px;
			.ssResultTitle {
				font-size: inherit;
				font-weight: inherit;
				padding: 0 4px;
			}

		}
		
		ul.better-autocomplete>li.result {
			border: none;
			padding: 12px 10px;
			&.highlight {
				background-color: darken($primary-color-100, 2%);
			}
		}
		.ssResult .ssResultImage {
			position: relative;
			padding: 0 4px;
			img {
				opacity: 0.4;
				width: 20px;
				height: 20px;
			}
		}
		

	}	
}



